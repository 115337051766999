import {GameRoundStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameRoundStore = defineStore("gameRound", {
    state: (): GameRoundStoreStateProps => ({
        items: [],
        roundItems: null,

        query: {
            page: 1,
            perPage: 50,
            name: ''
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 1,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        options: [],
        messageItems: [],
        gameResultData: {}
    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                currentRound: null,
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },

        async onList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/dashboard/info`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.roundItems = apiData.game_info;
                    this.pagination = apiData.pagination;

                    console.log("=>(gameRound.ts:61) this.roundItems", this.roundItems);
                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },

        async onGetMissionResult(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/dashboard/result`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.gameResultData = apiData.game_result;
                    this.gameResultData.team_items = [
                        ...this.gameResultData.team_items,
                        {
                            answer_media_items: [
                                {
                                    file_id: 0,
                                    height: 0,
                                    type: "image",
                                    url: "https://publish.pleyou.kr/qa/6/202411/34ece065c990aeadbf7c40145c956f8ca97225dd912ae9a0f0c5a7786a0838cd.png",
                                    width: 0
                                },{
                                    file_id: 0,
                                    height: 0,
                                    type: "video",
                                    url: "https://publish.pleyou.kr/qa/6/202411/eddd87634bc9f352d394957fda1ef4def7181d761721bf0fd97dd2121754f2eb.MOV",
                                    width: 0
                                },
                            ],
                            mission_items: [
                                {
                                    answer: "string",
                                    answer_media: {
                                        file_id: 58917435670579,
                                        height: 100,
                                        type: "image",
                                        url: "https://publish.pleyou.kr/qa/6/202411/34ece065c990aeadbf7c40145c956f8ca97225dd912ae9a0f0c5a7786a0838cd.png",
                                        width: 100
                                    },
                                    name: "string",
                                    point: "string",
                                    question: "string",
                                    solved_type: 0,
                                    solved_type_name: "string"
                                },
                                {
                                    answer: "string",
                                    answer_media: {
                                        file_id: 0,
                                        height: 0,
                                        type: "string",
                                        url: "string",
                                        width: 0
                                    },
                                    name: "string",
                                    point: "string",
                                    question: "string",
                                    solved_type: 0,
                                    solved_type_name: "성공"
                                },
                                {
                                    answer: "string",
                                    answer_media: {
                                        file_id: 0,
                                        height: 0,
                                        type: "string",
                                        url: "string",
                                        width: 0
                                    },
                                    name: "string",
                                    point: "string",
                                    question: "string",
                                    solved_type: 0,
                                    solved_type_name: "string"
                                },
                                {
                                    answer: "string",
                                    answer_media: {
                                        file_id: 0,
                                        height: 0,
                                        type: "video",
                                        url: "https://publish.pleyou.kr/qa/6/202411/eddd87634bc9f352d394957fda1ef4def7181d761721bf0fd97dd2121754f2eb.MOV",
                                        width: 0
                                    },
                                    name: "string",
                                    point: "string",
                                    question: "string",
                                    solved_type: 0,
                                    solved_type_name: "string"
                                },

                            ],
                            name: "string"
                        }
                    ]


                    console.log("=>(gameRound.ts:109) this.gameResultData", this.gameResultData);
                    // this.gameResultData.push({
                    //     AnswerMediaItems: [
                    //         {
                    //             file_id: 0,
                    //             height: 0,
                    //             type: "string",
                    //             url: "string",
                    //             width: 0
                    //         },
                    //     ],
                    //     MissionItems: [
                    //         {
                    //             Answer: "string",
                    //             Name: "string",
                    //             Point: "string",
                    //             Question: "string",
                    //             SolvedType: 0,
                    //             SolvedTypeName: "string"
                    //         }
                    //     ],
                    //     Name: "string"
                    // })


                    console.log("=>(gameRound.ts:83) this.gameResultData", this.gameResultData);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;



                })
        },

        async onGameStatusUpdate(gameId: number, status: string) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}/status/${status}`,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGetMessageList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/message`,
                onError: false,
            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;
                    this.messageItems = apiData.game_message_items

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {

                    this.listLoading = false;
                })
        },

        async onCreateMessage(gameId: number, gameTeamId: number, message: string) {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/game/${gameId}/message`,
                data: {
                    game_team_id: gameTeamId,
                    message: message
                }
            })
                .then((data) => {

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGetPdf(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/dashboard/pdf`,
                onError: false,
            })
                .then((data: any) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data.apiData;
                    // this.messageItems = apiData.game_message_items

                    const blob = new Blob([data], {type: 'application/pdf'});

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'downloaded_file.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);

                    console.log(blob, a);
                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {

                    this.listLoading = false;
                })
        },


    }

})
