import {useAdminAuthStore, useMasterAuthStore, usePlayAuthStore} from "@/stores";
import {NavigationGuardProps} from "@/models";
import {RouteRecordRaw} from "vue-router";

// https://v3.router.vuejs.org/kr/guide/advanced/navigation-guards.html
export const navigationGuard: NavigationGuardProps = {
  beforeEach: (to, _, next) => {
    const domain = window.location.hostname;
    const isAuth = to.meta?.auth ?? true;
    const masterAuthStore= useMasterAuthStore();
    const adminAuthStore = useAdminAuthStore();
    const playAuthStore = usePlayAuthStore();

    if (isAuth) {
      // check
      let checkFunc = adminAuthStore.onCheck;
      if (domain.includes("master.")) {
        checkFunc = masterAuthStore.onCheck;
      } else if (domain.includes("play.")) {
        checkFunc = playAuthStore.onCheck;
      }

      return checkFunc()
          .then(() => next())
          .catch(() => next({name: "signIn"}));
    }

    next();

  },
}

// Router Alignment
export const routes = () => {
  const domain = window.location.hostname;

  if (domain.includes("master.")) {
    return masterRoutes;
  } else if (domain.includes("-admin.")) {
    return tenantRoutes;
  } else if (domain.includes("ops.")) {
    return opsRoutes;
  } else if (domain.includes("play.")) {
    return playRoutes;
  }
  return [];
}

const masterRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/pages/BaseLayout.vue"),
    children: [
      {
        path: "auth",
        component: () => import("@/pages/master/auth/MasterAuthLayout.vue"),
        children: [
          {
            path: "/signIn",
            name: "signIn",
            component: () => import("@/pages/master/auth/MasterSignIn.vue"),
            meta: {auth: false}
          },
          {
            path: "/signUp",
            name: "signUp",
            component:  () => import("@/pages/master/auth/MasterSignUp.vue"),
            meta: {auth: false}
          }
        ]
      },
      {
        path: "",
        component: () => import("@/pages/master/home/MasterHomeLayout.vue"),
        children: [
          {
            path: "/master/tenant",
            name: "masterTenant",
            component: () => import("@/pages/master/home/tenant/MasterTenant.vue"),
            meta: {auth: true},
          },
          {
            path: "/master/tenant/:tenantId",
            name: "masterTenantItem",
            component: () => import("@/pages/master/home/tenant/MasterTenantItem.vue"),
            meta: {auth: true},
          },
          {
            path: "/master/master",
            name: "master",
            component: () => import("@/pages/master/home/master/Master.vue"),
            meta: {auth: true},
          },
          {
            path: "/master/master/:masterId",
            name: "masterItem",
            component: () => import("@/pages/master/home/master/MasterItem.vue"),
            meta: {auth: true},
          },
        ]
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const tenantRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/pages/BaseLayout.vue"),
    children: [
      {
        path: "auth",
        component: () => import("@/pages/tenant/auth/AuthLayout.vue"),
        children: [
          {
            path: "/signIn",
            name: "signIn",
            component: () => import("@/pages/tenant/auth/SignIn.vue"),
            meta: {auth: false}
          },
          {
            path: "/signUp",
            name: "signUp",
            component:  () => import("@/pages/tenant/auth/SignUp.vue"),
            meta: {auth: false}
          }
        ]
      },
      {
        path: "",
        component: () => import("@/pages/tenant/home/HomeLayout.vue"),
        children: [
          {
            path: "",
            name: "home",
            component: () => import("@/pages/tenant/home/Home.vue"),
            meta: {auth: true},
          },
          {
            path: "/channel",
            name: "channel",
            component: () => import("@/pages/tenant/home/channel/Channel.vue"),
            meta: {auth: true},
          },
          {
            path: "/channel/:channelId",
            name: "channelItem",
            component: () => import("@/pages/tenant/home/channel/ChannelItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/admin",
            name: "admin",
            component: () => import("@/pages/tenant/home/admin/Admin.vue"),
            meta: {auth: true}
          },
          {
            path: "/admin/:adminId",
            name: "adminItem",
            component: () => import("@/pages/tenant/home/admin/AdminItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/user",
            name: "user",
            component: () => import("@/pages/tenant/home/user/User.vue"),
            meta: {auth: true}
          },
          {
            path: "/user/:userId",
            name: "userItem",
            component: () => import("@/pages/tenant/home/user/UserItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/reward/:rewardSetId",
            name: "reward",
            component: () => import("@/pages/tenant/home/reward/Reward.vue"),
            meta: {auth: true}
          },
          {
            path: "/question/:questionSetId",
            name: "question",
            component: () => import("@/pages/tenant/home/question/Question.vue"),
            meta: {auth: true}
          },
          {
            path: "/game",
            name: "game",
            component: () => import("@/pages/tenant/home/game/Game.vue"),
            meta: {auth: true}
          },
          {
            path: "/game/:gameId/gameItem/:gameItem/:quarter",
            name: "gameItem",
            component: () => import("@/pages/tenant/home/game/GameItem.vue"),
            meta: {auth: true}
          },
          {
            path: "/game/:gameId/gameDashBoard",
            name: "gameDashBoard",
            component: () => import("@/pages/tenant/home/game/GameDashBoard.vue"),
            meta: {auth: true}
          },
        ],
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const opsRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/pages/BaseLayout.vue"),
    children: [
      {
        path: "auth",
        component: () => import("@/pages/ops/auth/OpsAuthLayout.vue"),
        children: [
          {
            path: "/signIn",
            name: "signIn",
            component: () => import("@/pages/ops/auth/OpsSignIn.vue"),
            meta: {auth: false}
          },
        ]
      },
      {
        path: "",
        component: () => import("@/pages/ops/home/OpsHomeLayout.vue"),
        children: [

        ]
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const playRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/pages/BaseLayout.vue"),
    children: [
      {
        path: "auth",
        component: () => import("@/pages/play/auth/PlayAuthLayout.vue"),
        children: [
          {
            path: "/signIn",
            name: "signIn",
            component: () => import("@/pages/play/auth/PlaySignIn.vue"),
            meta: {auth: false}
          },
        ]
      },
      {
        path: "",
        component: () => import("@/pages/play/home/PlayHomeLayout.vue"),
        children: [
          {
            path: "/play/dashBoard",
            name: "playDashBoard",
            component: () => import("@/pages/play/home/PlayDashBoard.vue"),
            meta: {auth: true},
          },
        ]
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];
