import {ChannelListItem, ChannelStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useChannelStore = defineStore("channel", {
  state: (): ChannelStoreStateProps => ({
    items: [],
    query: {
      page: 1,
      perPage: 50,
      name: ''
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    options: [],
    connectedList: [],
    connectedOptions: []

  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        channel_id: 0,
        name: "",
        latitude: 37.5152124,
        longitude: 127.0728853,
        discovery_distance: 5,
        class_type: null,
          resource_attachment: [
              {
                  file_id: 0,
                  height: 0,
                  resource_type: 0,
                  type: "",
                  url: "",
                  width: 0
              },
              {
                  file_id: 0,
                  height: 0,
                  resource_type: 0,
                  type: "",
                  url: "",
                  width: 0
              },
              {
                  file_id: 0,
                  height: 0,
                  resource_type: 0,
                  type: "",
                  url: "",
                  width: 0
              }
          ]
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },
    async onList(params?: object): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/channel`,
        onError: false,
        params: params || this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.items = apiData.channel_items.map((ele:ChannelListItem)=> {
            return {
              ...ele,
              num_connected_admins: ele.num_admins,
              num_connected_games: ele.num_games
            }
          });
          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },

    async onCreate() {
      this.formSaving = true;
      this.form!.discovery_distance = Number(this.form!.discovery_distance);
      return apiRequest.post({
        url: `/api/channel`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(channelId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/channel/${channelId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.form = apiData.channel;


          if (this.form!.resource_attachment.length === 0) {
              this.form!.resource_attachment = [
                  {
                      file_id: 0,
                      height: 0,
                      resource_type: 0,
                      type: "",
                      url: "",
                      width: 0
                  },
                  {
                      file_id: 0,
                      height: 0,
                      resource_type: 0,
                      type: "",
                      url: "",
                      width: 0
                  },
                  {
                      file_id: 0,
                      height: 0,
                      resource_type: 0,
                      type: "",
                      url: "",
                      width: 0
                  }
              ]
          }

          this.formOrigin = cloneDeep(apiData.channel);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async onGetOptions(): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/channel/options`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.options = apiData.channel_options;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.options = [];

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async getConnectedGameList(channelId: number): Promise<void> {
      return apiRequest.get({
        url: `/api/channel/${channelId}/game-connected`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.connectedList = apiData.game_briefs;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
        })
    },

    async getConnectedAdminList(channelId: number): Promise<void> {
      return apiRequest.get({
        url: `/api/channel/${channelId}/admin-connected`,
        onError: false,
      })
          .then((data) => {
            const {apiData} = data;
            this.connectedList = apiData.admin_briefs;

            return Promise.resolve(apiData);
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(() => {
          })
    },
    async getConnectedAdminOptions(channelId: number): Promise<void> {
      return apiRequest.get({
        url: `/api/channel/${channelId}/admin/options/distinct`,
        onError: false,
      })
          .then((data) => {
            const {apiData} = data;
            this.connectedOptions = apiData.channel_admin_options;

            return Promise.resolve(apiData);
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(() => {
          })
    },


    async onCreateConnectedChannelAdmin( channelId: number, adminId: number) {
      return apiRequest.post({
        url: `/api/channel/${channelId}/admin/${adminId}`,
        onError: false,
      })
          .then((data) => {

            return Promise.resolve(data.apiData);
          })
          .catch((error) => {
            return Promise.reject(error.apiData);
          })
          .finally(() => {
          })
    },
    async onDeleteConnectedChannelAdmin( channelId: number, adminId: number) {
          return apiRequest.delete({
            url: `/api/channel/${channelId}/admin/${adminId}`,
            onError: false,
          })
              .then((data) => {

                return Promise.resolve(data.apiData);
              })
              .catch((error) => {
                return Promise.reject(error.apiData);
              })
              .finally(() => {
              })
        },



    async onUpdate(channelId: number) {
      this.formSaving = true;
      this.form!.discovery_distance = Number(this.form!.discovery_distance);
      return apiRequest.put({
        url: `/api/channel/${channelId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onDelete(channelId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/channel/${channelId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })

    },



  }

})
